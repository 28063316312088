export const defaultTheme = {
    variables: {
        colors: {
            sale: 'rgba(206, 0, 0, 0.8)',
            border: '#eaeaea',
            primary: '#002f5c',
            yellow: 'rgba(255, 212, 0, 0.8)',
            gender: { selected: '#323232' },
            text: '#212529',
            availability: {
                inStock: '#078826',
            },
        },
        fontSizes: { small: '.75rem', normal: '1rem' },
        fonts: { primary: 'Exo 2', secondary: 'Open Sans' },
        button: { default: {}, gender: { height: '3rem' } },
        assets: {
            success:
                'data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCA1MTEuOTk5IDUxMS45OTkiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDUxMS45OTkgNTExLjk5OTsiIHhtbDpzcGFjZT0icHJlc2VydmUiPgo8Zz4KCTxnPgoJCTxwYXRoIGZpbGw9JyNmZmYnIGQ9Ik01MDYuMjMxLDc1LjUwOGMtNy42ODktNy42OS0yMC4xNTgtNy42OS0yNy44NDksMGwtMzE5LjIxLDMxOS4yMTFMMzMuNjE3LDI2OS4xNjNjLTcuNjg5LTcuNjkxLTIwLjE1OC03LjY5MS0yNy44NDksMAoJCQljLTcuNjksNy42OS03LjY5LDIwLjE1OCwwLDI3Ljg0OWwxMzkuNDgxLDEzOS40ODFjNy42ODcsNy42ODcsMjAuMTYsNy42ODksMjcuODQ5LDBsMzMzLjEzMy0zMzMuMTM2CgkJCUM1MTMuOTIxLDk1LjY2Niw1MTMuOTIxLDgzLjE5OCw1MDYuMjMxLDc1LjUwOHoiLz4KCTwvZz4KPC9nPgo8L3N2Zz4K',
            clipboard:
                'data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0iaXNvLTg4NTktMSI/Pgo8IS0tIEdlbmVyYXRvcjogQWRvYmUgSWxsdXN0cmF0b3IgMTkuMS4wLCBTVkcgRXhwb3J0IFBsdWctSW4gLiBTVkcgVmVyc2lvbjogNi4wMCBCdWlsZCAwKSAgLS0+CjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0iQ2FwYV8xIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB4PSIwcHgiIHk9IjBweCIKCSB2aWV3Qm94PSIwIDAgNDg4LjMgNDg4LjMiIHN0eWxlPSJlbmFibGUtYmFja2dyb3VuZDpuZXcgMCAwIDQ4OC4zIDQ4OC4zOyIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+CjxnPgoJPGc+CgkJPHBhdGggZD0iTTMxNC4yNSw4NS40aC0yMjdjLTIxLjMsMC0zOC42LDE3LjMtMzguNiwzOC42djMyNS43YzAsMjEuMywxNy4zLDM4LjYsMzguNiwzOC42aDIyN2MyMS4zLDAsMzguNi0xNy4zLDM4LjYtMzguNlYxMjQKCQkJQzM1Mi43NSwxMDIuNywzMzUuNDUsODUuNCwzMTQuMjUsODUuNHogTTMyNS43NSw0NDkuNmMwLDYuNC01LjIsMTEuNi0xMS42LDExLjZoLTIyN2MtNi40LDAtMTEuNi01LjItMTEuNi0xMS42VjEyNAoJCQljMC02LjQsNS4yLTExLjYsMTEuNi0xMS42aDIyN2M2LjQsMCwxMS42LDUuMiwxMS42LDExLjZWNDQ5LjZ6Ii8+CgkJPHBhdGggZD0iTTQwMS4wNSwwaC0yMjdjLTIxLjMsMC0zOC42LDE3LjMtMzguNiwzOC42YzAsNy41LDYsMTMuNSwxMy41LDEzLjVzMTMuNS02LDEzLjUtMTMuNWMwLTYuNCw1LjItMTEuNiwxMS42LTExLjZoMjI3CgkJCWM2LjQsMCwxMS42LDUuMiwxMS42LDExLjZ2MzI1LjdjMCw2LjQtNS4yLDExLjYtMTEuNiwxMS42Yy03LjUsMC0xMy41LDYtMTMuNSwxMy41czYsMTMuNSwxMy41LDEzLjVjMjEuMywwLDM4LjYtMTcuMywzOC42LTM4LjYKCQkJVjM4LjZDNDM5LjY1LDE3LjMsNDIyLjM1LDAsNDAxLjA1LDB6Ii8+Cgk8L2c+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPGc+CjwvZz4KPC9zdmc+Cg==',
        },
    },
    productColWidth: [10, 10, 5],
    grid: { gutter: 10, columns: 20 },
    fonts: [],
    icons: [
        {
            name: 'apple-touch-icon',
            url: '$assets.icons.appleTouch',
        },
        {
            name: 'favicon',
            url: '$assets.icons.favicon',
        },
    ],
    global: {
        styles: [
            {
                values: { fontFamily: '"$fonts.primary"' },
                order: ['fontFamily'],
            },
        ],
    },
    breakpoints: [
        { name: 'xs', value: '0px' },
        { name: 'sm', value: '576px' },
        { name: 'md', value: '768px' },
        { name: 'lg', value: '992px' },
        { name: 'xl', value: '1200px' },
        { name: 'xxl', value: '1400px' },
        { name: '3xl', value: '1600px' },
    ],
    carousel: {
        control: {
            styles: [
                {
                    values: {
                        backgroundImage:
                            'url($assets.icons.caret)',
                        backgroundPosition: 'center',
                        backgroundRepeat: 'no-repeat',
                        backgroundSize: '50%',
                    },
                    order: [
                        'backgroundImage',
                        'backgroundPosition',
                        'backgroundRepeat',
                        'backgroundSize',
                    ],
                },
            ],
        },
    },
    voucher: {
        modal: {
            backdrop: {
                styles: [
                    {
                        values: {
                            display: 'block',
                            top: '0',
                            left: '0',
                            width: '100%',
                            height: '100%',
                            position: 'fixed',
                            backgroundColor:
                                'rgba(0, 0, 0, 0.5)',
                        },
                        order: [
                            'display',
                            'top',
                            'left',
                            'width',
                            'height',
                            'position',
                            'backgroundColor',
                        ],
                    },
                ],
            },
            container: {
                styles: [
                    {
                        values: {
                            position: 'relative',
                            margin: 'auto',
                            width: 'calc(100% - 1.5rem)',
                            backgroundColor: 'white',
                            padding: '1.5rem',
                            display: 'flex',
                            flexDirection: 'column',
                            lineHeight: '1.5',
                            borderRadius: '4px',
                        },
                        order: [
                            'position',
                            'margin',
                            'width',
                            'backgroundColor',
                            'padding',
                            'display',
                            'flexDirection',
                            'lineHeight',
                            'borderRadius',
                        ],
                    },
                    {
                        values: {
                            width: '500px',
                        },
                        order: ['width'],
                    },
                ],
            },
            text: {
                styles: [
                    {
                        values: {
                            fontSize: '1rem',
                            marginTop: '1.5rem',
                            textAlign: 'center',
                        },
                        order: [
                            'fontSize',
                            'marginTop',
                            'textAlign',
                        ],
                    },
                    null,
                    {
                        values: {
                            fontSize: '1.2rem',
                        },
                        order: ['fontSize'],
                    },
                ],
            },
            hint: {
                styles: [
                    {
                        values: {
                            fontSize: '.75rem',
                            marginTop: '.5rem',
                            marginBottom: '.5rem',
                            textAlign: 'center',
                        },
                        order: [
                            'fontSize',
                            'marginTop',
                            'marginBottom',
                            'textAlign',
                        ],
                    },
                    null,
                    {
                        values: {
                            fontSize: '.975rem',
                        },
                        order: ['fontSize'],
                    },
                ],
            },
            code: {
                wrapper: {
                    styles: [
                        {
                            values: {
                                position: 'relative',
                            },
                            order: ['position'],
                        },
                    ],
                },
                input: {
                    styles: [
                        {
                            values: {
                                border: '1px dashed #c8c4c4',
                                borderRadius: '4px',
                                height: '3rem',
                                marginBottom: '1rem',
                                marginTop: '.5rem',
                                width: '100%',
                                paddingLeft: '0.8rem',
                            },
                            order: [
                                'border',
                                'borderRadius',
                                'height',
                                'marginBottom',
                                'marginTop',
                                'width',
                                'paddingLeft',
                            ],
                        },
                    ],
                },
                button: {
                    styles: [
                        {
                            values: {
                                position: 'absolute',
                                right: '2px',
                                top: '9px',
                                height: 'calc(3rem - 3px)',
                                width: 'calc(3rem - 2px)',
                                backgroundColor: '#e7e7e7',
                                backgroundImage:
                                    'url($assets.clipboard)',
                                backgroundSize: '50%',
                                backgroundRepeat:
                                    'no-repeat',
                                backgroundPosition:
                                    'center',
                                cursor: 'pointer',
                                borderRadius: '0 4px 4px 0',
                            },
                            order: [
                                'position',
                                'right',
                                'top',
                                'height',
                                'width',
                                'backgroundColor',
                                'backgroundImage',
                                'backgroundSize',
                                'backgroundRepeat',
                                'backgroundPosition',
                                'cursor',
                                'borderRadius',
                            ],
                        },
                    ],
                    clicked: {
                        styles: [
                            {
                                values: {
                                    backgroundColor:
                                        '#8cd28a',
                                    backgroundImage:
                                        'url($assets.success)',
                                },
                                order: [
                                    'backgroundColor',
                                    'backgroundImage',
                                ],
                            },
                        ],
                    },
                },
            },
            controls: {
                button: {
                    styles: [
                        {
                            values: {
                                height: '3rem',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                backgroundColor: '#e9484d',
                                color: 'white',
                                cursor: 'pointer',
                                borderRadius: '2px',
                                fontSize: '1rem',
                            },
                            order: [
                                'height',
                                'display',
                                'justifyContent',
                                'alignItems',
                                'backgroundColor',
                                'color',
                                'cursor',
                                'borderRadius',
                                'fontSize',
                            ],
                        },
                    ],
                },
            },
            logo: {
                container: {
                    styles: [
                        {
                            values: { height: '1.8rem' },
                            order: ['height'],
                        },
                    ],
                },
                image: {
                    styles: [
                        {
                            values: {
                                backgroundImage:
                                    'url($assets.logoLayer)',
                                backgroundSize: 'contain',
                                backgroundRepeat:
                                    'no-repeat',
                                backgroundPosition:
                                    'center',
                                height: '100%',
                                width: '100%',
                            },
                            order: [
                                'backgroundImage',
                                'backgroundSize',
                                'backgroundRepeat',
                                'backgroundPosition',
                                'height',
                                'width',
                            ],
                        },
                    ],
                },
            },
        },
    },
    logo: {
        styles: [
            {
                values: {
                    height: '100%',
                    width: '100%',
                    backgroundRepeat: 'no-repeat',
                    backgroundSize: 'contain',
                    cursor: 'pointer',
                },
                order: [
                    'height',
                    'width',
                    'backgroundRepeat',
                    'backgroundSize',
                    'cursor',
                ],
            },
        ],
        header: {
            styles: [
                {
                    values: {
                        backgroundImage:
                            'url($assets.logoHeader)',
                    },
                    order: ['backgroundImage'],
                },
            ],
        },
        layer: {
            styles: [
                {
                    values: {
                        backgroundImage:
                            'url($assets.logoLayer)',
                    },
                    order: ['backgroundImage'],
                },
            ],
        },
    },
    menu: {
        container: {
            styles: [
                {
                    values: { height: '50px' },
                    order: ['height'],
                },
            ],
        },
        icon: {
            styles: [
                {
                    values: {
                        width: '18px',
                        height: '100%',
                        backgroundSize: 'contain',
                        backgroundPosition: 'center',
                        backgroundRepeat: 'no-repeat',
                    },
                    order: [
                        'width',
                        'height',
                        'backgroundSize',
                        'backgroundPosition',
                        'backgroundRepeat',
                    ],
                },
            ],
            open: {
                styles: [
                    {
                        values: {
                            backgroundImage:
                                'url($assets.icons.ham)',
                        },
                        order: ['backgroundImage'],
                    },
                ],
            },
            close: {
                styles: [
                    {
                        values: {
                            backgroundImage:
                                'url($assets.icons.cross)',
                        },
                        order: ['backgroundImage'],
                    },
                ],
            },
        },
    },
    dropdown: {
        wrapper: {
            styles: [
                {
                    values: {
                        width: '100%',
                        height: 'auto',
                        backgroundColor: 'white',
                    },
                    order: [
                        'width',
                        'height',
                        'backgroundColor',
                    ],
                },
            ],
        },
    },
    header: {
        container: {
            styles: [
                null,
                null,
                null,
                {
                    values: {
                        paddingTop: '2rem',
                        borderWidth: '0px',
                        borderBottomWidth: '1px',
                        borderStyle: 'solid',
                        borderColor: '$colors.border',
                    },
                    order: [
                        'paddingTop',
                        'borderWidth',
                        'borderBottomWidth',
                        'borderStyle',
                        'borderColor',
                    ],
                },
            ],
        },
    },
    attributes: {
        buttonSelect: {
            container: {
                styles: [
                    {
                        values: {
                            display: 'flex',
                            height: 'auto',
                            flexDirection: 'column',
                        },
                        order: [
                            'display',
                            'height',
                            'flexDirection',
                        ],
                    },
                ],
            },
            group: {
                styles: [
                    {
                        values: {
                            display: 'flex',
                            height: 'auto',
                            justifyContent: 'flexStart',
                            flexWrap: 'wrap',
                            marginBottom: '-.4rem',
                        },
                        order: [
                            'display',
                            'height',
                            'justifyContent',
                            'flexWrap',
                            'marginBottom',
                        ],
                    },
                ],
            },
            label: {
                styles: [
                    {
                        values: {
                            fontSize: '.85rem',
                            fontWeight: '700',
                            display: 'block',
                            width: '100%',
                            marginBottom: '.3rem',
                        },
                        order: [
                            'fontSize',
                            'fontWeight',
                            'display',
                            'width',
                            'marginBottom',
                        ],
                    },
                ],
            },
        },
        button: {
            '&:hover': {
                styles: [
                    {
                        values: {
                            backgroundColor:
                                '$colors.border',
                        },
                        order: ['backgroundColor'],
                    },
                ],
            },
            styles: [
                {
                    values: {
                        height: 'auto',
                        width: 'auto',
                        minWidth: '2.5rem',
                        minHeight: '2.5rem',
                        padding: '.3rem',
                        fontSize: '.8rem',
                        marginRight: '.4rem',
                        marginBottom: '.4rem',
                        cursor: 'pointer',
                        border: '1px solid black',
                        borderColor: '$colors.border',
                        backgroundColor: 'white',
                        outline: '0',
                        color: 'black',
                        borderRadius: '2px',
                    },
                    order: [
                        'height',
                        'width',
                        'minWidth',
                        'minHeight',
                        'padding',
                        'fontSize',
                        'marginRight',
                        'marginBottom',
                        'cursor',
                        'border',
                        'borderColor',
                        'backgroundColor',
                        'outline',
                        'color',
                        'borderRadius',
                    ],
                },
            ],
            selected: {
                styles: [
                    {
                        values: {
                            borderColor: 'black',
                            fontWeight: '600',
                        },
                        order: [
                            'borderColor',
                            'fontWeight',
                        ],
                    },
                ],
            },
        },
    },
    eyecatchers: {
        general: { position: 'inside' },
        container: {
            styles: [
                {
                    values: {
                        display: 'flex',
                        flexDirection: 'column-reverse',
                        fontFamily:
                            '"$fonts.secondary" !important',
                        height: '100%',
                        alignItems: 'flex-start',
                    },
                    order: [
                        'display',
                        'flexDirection',
                        'fontFamily',
                        'height',
                        'alignItems',
                    ],
                },
            ],
        },
        item: {
            default: {
                styles: [
                    {
                        values: {
                            height: '1.2rem',
                            display: 'inline-flex',
                            alignItems: 'center',
                            padding: '0 0.6rem',
                            borderRadius: '2px',
                            fontSize: '.6rem',
                            fontWeight: '800',
                            textTransform: 'uppercase',
                            marginLeft: '.5rem',
                        },
                        order: [
                            'height',
                            'display',
                            'alignItems',
                            'padding',
                            'borderRadius',
                            'fontSize',
                            'fontWeight',
                            'textTransform',
                            'marginLeft',
                        ],
                    },
                    {
                        values: {
                            height: '1.6rem',
                            display: 'inline-flex',
                            alignItems: 'center',
                            padding: '0 0.6rem',
                            borderRadius: '4px',
                            fontSize: '.8rem',
                            fontWeight: '800',
                            textTransform: 'uppercase',
                            marginLeft: '.5rem',
                            left: '0px',
                        },
                        order: [
                            'height',
                            'display',
                            'alignItems',
                            'padding',
                            'borderRadius',
                            'fontSize',
                            'fontWeight',
                            'textTransform',
                            'marginLeft',
                            'left',
                        ],
                    },
                ],
            },
            shipping: {
                styles: [
                    {
                        values: {
                            backgroundColor:
                                '$colors.yellow',
                            order: '0',
                            marginBottom: '0.2rem',
                        },
                        order: [
                            'backgroundColor',
                            'order',
                            'marginBottom',
                        ],
                    },
                ],
            },
            promotion: {
                styles: [
                    {
                        values: {
                            backgroundColor: 'black',
                            color: 'white',
                            order: '1',
                            marginBottom: '0.3rem',
                        },
                        order: [
                            'backgroundColor',
                            'color',
                            'order',
                            'marginBottom',
                        ],
                    },
                ],
            },
            sale: {
                styles: [
                    {
                        values: {
                            backgroundColor: '$colors.sale',
                            height: '1.6rem',
                            color: 'white',
                            marginTop: '.5rem',
                            fontSize: '.9rem',
                            marginBottom: 'auto',
                            order: '2',
                        },
                        order: [
                            'backgroundColor',
                            'height',
                            'color',
                            'marginTop',
                            'fontSize',
                            'marginBottom',
                            'order',
                        ],
                    },
                    {
                        values: {
                            height: '2rem',
                        },
                        order: ['height'],
                    },
                ],
            },
            saleInPrice: {
                layer: {
                    styles: [
                        {
                            values: {
                                height: '1.5rem !important',
                                fontSize: '1rem',
                                padding: '0 0.6rem',
                                marginRight: '0',
                            },
                            order: [
                                'height',
                                'fontSize',
                                'padding',
                                'marginRight',
                            ],
                        },
                        {
                            values: {
                                height: '1.5rem !important',
                                fontSize: '1rem',
                                padding: '0 0.6rem',
                            },
                            order: [
                                'height',
                                'fontSize',
                                'padding',
                            ],
                        },
                        {
                            values: {
                                height: '1.5rem !important',
                                fontSize: '1rem',
                                padding: '0 0.6rem',
                            },
                            order: [
                                'height',
                                'fontSize',
                                'padding',
                            ],
                        },
                    ],
                },
                styles: [
                    {
                        values: {
                            marginTop: '0rem',
                            position: 'static !important',
                            height: '1.2rem !important',
                            fontSize: '.8rem',
                            marginBottom: '0rem',
                            padding: '0 0.4rem',
                            marginRight: '.5rem',
                        },
                        order: [
                            'position',
                            'marginTop',
                            'height',
                            'marginBottom',
                            'fontSize',
                            'padding',
                            'marginRight',
                        ],
                    },
                    null,
                    null,
                    {
                        values: {
                            height: '1.5rem !important',
                            fontSize: '1rem',
                            padding: '0 0.6rem',
                        },
                        order: [
                            'height',
                            'fontSize',
                            'padding',
                        ],
                    },
                ],
            },
        },
    },
    productsGrid: {
        banner: {
            styles: [
                {
                    values: {
                        backgroundSize: 'contain',
                        backgroundRepeat: 'no-repeat',
                        marginTop: '1rem',
                        height: 'auto',
                        cursor: 'pointer',
                    },
                    order: [
                        'backgroundSize',
                        'backgroundRepeat',
                        'marginTop',
                        'height',
                        'cursor',
                    ],
                },
                null,
                {
                    values: {
                        marginTop: '1.5rem',
                    },
                    order: ['marginTop'],
                },
            ],
        },
        container: {
            top: {
                styles: [
                    {
                        values: {
                            backgroundColor: 'white',
                        },
                        order: ['backgroundColor'],
                    },
                ],
            },
        },
        headline: {
            styles: [
                {
                    values: {
                        alignItems: 'center',
                        display: 'flex',
                        fontSize: '1.1rem',
                        fontWeight: '400',
                        height: '4rem',
                        lineHeight: '1.5',
                        marginBottom: '0',
                        marginTop: '0',
                    },
                    order: [
                        'alignItems',
                        'display',
                        'fontSize',
                        'fontWeight',
                        'height',
                        'lineHeight',
                        'marginBottom',
                        'marginTop',
                    ],
                },
                {
                    values: {
                        fontSize: '1.8rem',
                        height: '6rem',
                    },
                    order: ['fontSize', 'height'],
                },
                {
                    values: {
                        fontSize: '2rem',
                        height: '8rem',
                    },
                    order: ['fontSize', 'height'],
                },
            ],
        },
        product: {
            details: {
                brand: {
                    styles: [
                        {
                            values: {
                                fontWeight: 'bold',
                            },
                            order: ['fontWeight'],
                        },
                    ],
                },
                attributes: {
                    container: {
                        styles: [
                            {
                                values: {
                                    position: 'absolute',
                                    backgroundColor:
                                        'white',
                                    height: 'auto',
                                    paddingBottom: '1rem',
                                    paddingTop: '.8rem',
                                    zIndex: '100000',
                                    left: '0',
                                    width: '100%',
                                },
                                order: [
                                    'position',
                                    'backgroundColor',
                                    'height',
                                    'paddingBottom',
                                    'paddingTop',
                                    'zIndex',
                                    'left',
                                    'width',
                                ],
                            },
                        ],
                    },
                    buttonSelect: {
                        label: {
                            styles: [
                                {
                                    values: {
                                        textAlign: 'center',
                                    },
                                    order: ['textAlign'],
                                },
                            ],
                        },
                        container: {
                            styles: [
                                {
                                    values: {
                                        justifyContent:
                                            'center',
                                    },
                                    order: [
                                        'justifyContent',
                                    ],
                                },
                            ],
                        },
                        group: {
                            styles: [
                                {
                                    values: {
                                        justifyContent:
                                            'center',
                                    },
                                    order: [
                                        'justifyContent',
                                    ],
                                },
                            ],
                        },
                    },
                },
                title: {
                    styles: [
                        {
                            values: {
                                fontSize: 'inherit',
                                fontWeight: '400',
                                lineHeight: '1.5',
                                marginBottom: '.25rem',
                                marginTop: '.25rem',
                            },
                            order: [
                                'fontSize',
                                'fontWeight',
                                'lineHeight',
                                'marginBottom',
                                'marginTop',
                            ],
                        },
                    ],
                },
                styles: [
                    {
                        values: {
                            textAlign: 'left',
                            position: 'relative',
                            marginTop: '1rem',
                            padding: '0 0rem 1rem 0rem',
                            fontSize: '.9rem',
                        },
                        order: [
                            'textAlign',
                            'position',
                            'marginTop',
                            'padding',
                            'fontSize',
                        ],
                    },
                    null,
                    {
                        values: {
                            fontSize: '1rem',
                        },
                        order: ['fontSize'],
                    },
                ],
            },
            eyecatchers: {
                styles: [
                    {
                        values: {
                            position: 'absolute',
                            top: '0',
                            left: '0',
                            zIndex: '100',
                            pointerEvents: 'none',
                            width: '100%',
                        },
                        order: [
                            'position',
                            'top',
                            'left',
                            'zIndex',
                            'pointerEvents',
                            'width',
                        ],
                    },
                ],
            },
            images: {
                container: {},
                controls: {
                    dots: {
                        styles: [
                            {
                                values: {
                                    position: 'absolute',
                                    bottom: '0rem',
                                    width: '100%',
                                    left: '0',
                                    height: '30px',
                                    zIndex: '1000',
                                    display: 'flex',
                                    justifyContent:
                                        'center',
                                    alignItems: 'center',
                                },
                                order: [
                                    'position',
                                    'bottom',
                                    'width',
                                    'left',
                                    'height',
                                    'zIndex',
                                    'display',
                                    'justifyContent',
                                    'alignItems',
                                ],
                            },
                        ],
                    },
                    dot: {
                        styles: [
                            {
                                values: {
                                    height: '8px',
                                    width: '8px',
                                    minWidth: '8px',
                                    backgroundColor:
                                        'black',
                                    borderRadius: '8px',
                                    opacity: '.2',
                                    marginLeft: '8px',
                                    marginRight: '8px',
                                },
                                order: [
                                    'height',
                                    'width',
                                    'minWidth',
                                    'backgroundColor',
                                    'borderRadius',
                                    'opacity',
                                    'marginLeft',
                                    'marginRight',
                                ],
                            },
                        ],
                        active: {
                            styles: [
                                {
                                    values: {
                                        opacity: '.5',
                                    },
                                    order: ['opacity'],
                                },
                            ],
                        },
                        condensed: {
                            styles: [
                                {
                                    values: {
                                        marginLeft: '4px',
                                        marginRight: '4px',
                                    },
                                    order: [
                                        'marginLeft',
                                        'marginRight',
                                    ],
                                },
                            ],
                        },
                    },
                },
            },
            lowestPrice: {
                styles: [
                    {
                        values: {
                            opacity: '.5',
                            fontSize: '.6rem',
                        },
                        order: ['opacity', 'fontSize'],
                    },
                    null,
                    {
                        values: {
                            opacity: '.5',
                            fontSize: '.7rem',
                        },
                        order: ['opacity', 'fontSize'],
                    },
                ],
            },
            priceInformation: {
                styles: [
                    {
                        values: {
                            paddingTop: '0.3rem',
                            opacity: '.5',
                            fontSize: '.6rem',
                            paddingBottom: '0.3rem',
                        },
                        order: [
                            'opacity',
                            'fontSize',
                            'paddingTop',
                            'paddingBottom',
                        ],
                    },
                    null,
                    {
                        values: {
                            paddingTop: '0.3rem',
                            paddingBottom: '0.3rem',
                            opacity: '.5',
                            fontSize: '.7rem',
                        },
                        order: [
                            'opacity',
                            'fontSize',
                            'paddingTop',
                            'paddingBottom',
                        ],
                    },
                ],
            },
            price: {
                styles: [
                    {
                        values: {
                            fontWeight: '600',
                            lineHeight: '1.5',
                        },
                        order: ['fontWeight', 'lineHeight'],
                    },
                ],
            },
            oldPrice: {
                styles: [
                    {
                        values: {
                            display: 'block',
                            marginRight: '.5rem',
                            textDecoration: 'line-through',
                            whiteSpace: 'nowrap',
                        },
                        order: [
                            'marginRight',
                            'textDecoration',
                            'display',
                            'whiteSpace',
                        ],
                    },
                    null,
                    null,
                    {
                        values: {
                            display: 'inline',
                            marginLeft: '0',
                        },
                        order: ['display', 'marginLeft'],
                    },
                ],
            },
            salePrice: {
                styles: [
                    {
                        values: { color: '$colors.sale' },
                        order: ['color'],
                    },
                ],
            },
            basePrice: {
                styles: [
                    {
                        values: { fontSize: '.8rem' },
                        order: ['fontSize'],
                    },
                ],
            },
        },
        controls: {
            badge: {
                '& span': {
                    styles: [
                        {
                            values: {
                                display: 'inline-flex',
                                alignItems: 'center',
                                marginRight: '.8rem',

                                borderWidth: '1px',
                                borderColor:
                                    '$colors.border',
                                borderStyle: 'solid',
                                fontSize: '.8rem',
                                height: '2rem',
                            },
                            order: [
                                'display',
                                'alignItems',
                                'marginRight',
                                'borderWidth',
                                'borderColor',
                                'borderStyle',
                                'fontSize',
                                'height',
                            ],
                        },
                    ],
                },
                styles: [
                    {
                        values: {
                            display: 'inline-flex',
                            alignItems: 'center',
                            marginRight: '.8rem',
                            marginTop: '0.5rem',
                            fontSize: '.8rem',
                            height: '2rem',
                            marginBottom: '.5rem',
                        },
                        order: [
                            'display',
                            'alignItems',
                            'marginRight',
                            'fontSize',
                            'height',
                            'marginBottom',
                            'marginTop',
                        ],
                    },
                ],
            },
            gender: {
                container: {
                    styles: [
                        {
                            values: {
                                display: 'flex',
                                alignItems: 'center',
                                marginTop: '1rem',
                                marginBottom: '1rem',
                            },
                            order: [
                                'display',
                                'alignItems',
                                'marginTop',
                                'marginBottom',
                            ],
                        },
                    ],
                },
                button: {
                    default: {
                        '&:hover': {
                            styles: [
                                {
                                    values: {
                                        backgroundColor:
                                            '$colors.gender.selected',
                                        color: 'white',
                                    },
                                    order: [
                                        'backgroundColor',
                                        'color',
                                    ],
                                },
                            ],
                        },
                        styles: [
                            {
                                values: {
                                    flex: '1',
                                    cursor: 'pointer',
                                    height: '3rem',
                                    border: '2px solid $colors.gender.selected',
                                    borderRadius: '5px',
                                    display: 'flex',
                                    alignItems: 'center',
                                    padding: '0 3rem',
                                    paddingLeft: '1rem',
                                },
                                order: [
                                    'flex',
                                    'cursor',
                                    'height',
                                    'border',
                                    'borderRadius',
                                    'display',
                                    'alignItems',
                                    'padding',
                                    'paddingLeft',
                                ],
                            },
                        ],
                    },
                    selected: {
                        styles: [
                            {
                                values: {
                                    backgroundColor:
                                        '$colors.gender.selected',
                                    color: 'white',
                                },
                                order: [
                                    'backgroundColor',
                                    'color',
                                ],
                            },
                        ],
                    },
                    disabled: {
                        styles: [
                            {
                                values: {
                                    backgroundColor:
                                        '#eeeeee',
                                    color: '#bbbbbb',
                                    cursor: 'not-allowed',
                                    pointerEvents: 'none',
                                    borderColor: '#eeeeee',
                                },
                                order: [
                                    'backgroundColor',
                                    'color',
                                    'cursor',
                                    'pointerEvents',
                                    'borderColor',
                                ],
                            },
                        ],
                    },
                },
                icon: {
                    wrapper: {
                        styles: [
                            {
                                values: {
                                    height: '1.25rem',
                                    width: '1.25rem',
                                    border: '1px solid #222',
                                    marginRight: '1rem',
                                    backgroundColor:
                                        'white',
                                },
                                order: [
                                    'height',
                                    'width',
                                    'border',
                                    'marginRight',
                                    'backgroundColor',
                                ],
                            },
                        ],
                    },
                },
                text: {
                    styles: [
                        {
                            values: {
                                fontSize: '1.2rem',
                                fontWeight: '600',
                                textTransform: 'uppercase',
                            },
                            order: [
                                'fontSize',
                                'fontWeight',
                                'textTransform',
                            ],
                        },
                    ],
                },
            },
            priceFilter: {
                button: {
                    disabled: {
                        styles: [
                            {
                                values: {
                                    color: '#ffffff',
                                    backgroundColor:
                                        '#dddddd',
                                },
                                order: [
                                    'color',
                                    'backgroundColor',
                                ],
                            },
                        ],
                    },
                    enabled: {
                        styles: [
                            {
                                values: {
                                    color: '#ffffff',
                                    backgroundColor:
                                        '#000000',
                                },
                                order: [
                                    'color',
                                    'backgroundColor',
                                ],
                            },
                        ],
                    },
                },
                slider: {
                    thumb: {
                        styles: [
                            {
                                values: {
                                    backgroundColor:
                                        '#000000',
                                },
                                order: ['backgroundColor'],
                            },
                        ],
                    },
                    styles: [
                        {
                            values: {
                                backgroundColor: '#000000',
                            },
                            order: ['backgroundColor'],
                        },
                    ],
                },
            },
        },
        previousPagination: {
            wrapper: {
                styles: [
                    {
                        values: {
                            marginTop: '1rem',
                            marginBottom: '1rem',
                        },
                        order: [
                            'marginTop',
                            'marginBottom',
                        ],
                    },
                ],
            },
        },
        pagination: {
            wrapper: {
                styles: [
                    {
                        values: {
                            marginTop: '50px',
                            marginBottom: '50px',
                            fontFamily:
                                '"$fonts.secondary"',
                        },
                        order: [
                            'marginTop',
                            'marginBottom',
                            'fontFamily',
                        ],
                    },
                ],
            },
            button: {
                styles: [
                    {
                        values: {
                            height: '3rem',
                            borderStyle: 'solid',
                            borderWidth: '1px',
                            borderColor: '$colors.primary',
                            textTransform: 'uppercase',
                            fontSize: '.9rem',
                            fontWeight: '600',
                            cursor: 'pointer',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            minWidth: '300px',
                            marginTop: '1.5rem',
                        },
                        order: [
                            'height',
                            'borderStyle',
                            'borderWidth',
                            'borderColor',
                            'textTransform',
                            'fontSize',
                            'fontWeight',
                            'cursor',
                            'display',
                            'justifyContent',
                            'alignItems',
                            'minWidth',
                            'marginTop',
                        ],
                    },
                    {
                        values: {
                            height: '4rem',
                            fontSize: '1.2rem',
                        },
                        order: ['height', 'fontSize'],
                    },
                ],
            },
            row: {
                styles: [
                    {
                        values: {
                            justifyContent: 'center',
                        },
                        order: ['justifyContent'],
                    },
                ],
            },
            container: {
                styles: [
                    {
                        values: {
                            display: 'flex',
                            justifyContent: 'center',
                            width: '300px',
                            flexDirection: 'column',
                        },
                        order: [
                            'display',
                            'justifyContent',
                            'width',
                            'flexDirection',
                        ],
                    },
                ],
            },
            range: {
                '&:before': {
                    styles: [
                        {
                            values: {
                                content: '""',
                                height: '100%',
                                backgroundColor:
                                    '$colors.primary',
                            },
                            order: [
                                'content',
                                'height',
                                'backgroundColor',
                            ],
                        },
                    ],
                },
                styles: [
                    {
                        values: {
                            height: '3px',
                            width: '300px',
                            backgroundColor:
                                '$colors.border',
                            display: 'flex',
                        },
                        order: [
                            'height',
                            'width',
                            'backgroundColor',
                            'display',
                        ],
                    },
                ],
            },
            status: {
                styles: [
                    {
                        values: {
                            fontSize: '.8rem',
                            textAlign: 'center',
                            marginBottom: '1rem',
                        },
                        order: [
                            'fontSize',
                            'textAlign',
                            'marginBottom',
                        ],
                    },
                ],
            },
            jump: {
                styles: [
                    {
                        values: {
                            color: '$colors.primary',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            marginTop: '2rem',
                            cursor: 'pointer',
                        },
                        order: [
                            'color',
                            'display',
                            'justifyContent',
                            'alignItems',
                            'marginTop',
                            'cursor',
                        ],
                    },
                ],
            },
        },
    },
    productLayer: {
        modal: {
            '& > *': {
                styles: [
                    {
                        values: {
                            backgroundColor: 'white',
                        },
                        order: ['backgroundColor'],
                    },
                ],
            },
            styles: [
                {
                    values: {
                        position: 'relative',
                        minHeight: '400px',
                        height: 'auto',
                        left: 'auto',
                        margin: 'auto',
                        marginTop: '0',
                        display: 'flex',
                        flexDirection: 'column',
                        width: 'calc(100% - 1.5rem)',
                    },
                    order: [
                        'position',
                        'minHeight',
                        'height',
                        'left',
                        'margin',
                        'marginTop',
                        'display',
                        'flexDirection',
                        'width',
                    ],
                },
                {
                    values: {
                        width: '60vw',
                        minWidth: '560px',
                    },
                    order: ['width', 'minWidth'],
                },
                {
                    values: {
                        width: '900px',
                        flexDirection: 'row',
                        marginTop: 'auto',
                    },
                    order: [
                        'width',
                        'flexDirection',
                        'marginTop',
                    ],
                },
            ],
        },
        wrapper: {
            styles: [
                {
                    values: {
                        position: 'absolute',
                        width: '100%',
                        height: '100%',
                        top: '0',
                        left: '0',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        zIndex: '1000000000',
                    },
                    order: [
                        'position',
                        'width',
                        'height',
                        'top',
                        'left',
                        'display',
                        'justifyContent',
                        'alignItems',
                        'zIndex',
                    ],
                },
                null,
                {
                    values: {
                        position: 'fixed',
                    },
                    order: ['position'],
                },
            ],
        },
        backdrop: {
            styles: [
                {
                    values: {
                        display: 'block',
                        top: '0',
                        left: '0',
                        width: '100%',
                        height: '100%',
                        position: 'fixed',
                        backgroundColor:
                            'rgba(0, 0, 0, 0.5)',
                    },
                    order: [
                        'display',
                        'top',
                        'left',
                        'width',
                        'height',
                        'position',
                        'backgroundColor',
                    ],
                },
            ],
        },
        header: {
            logo: {
                styles: [
                    {
                        values: {
                            flex: '1',
                            paddingLeft: '.75rem',
                            height: '2rem',
                            alignSelf: 'center',
                            display: 'flex',
                            alignItems: 'center',
                        },
                        order: [
                            'flex',
                            'paddingLeft',
                            'height',
                            'alignSelf',
                            'display',
                            'alignItems',
                        ],
                    },
                    null,
                    {
                        values: {
                            alignSelf: 'flex-end',
                            height: '2rem',
                            paddingLeft: '2rem',
                        },
                        order: [
                            'alignSelf',
                            'height',
                            'paddingLeft',
                        ],
                    },
                ],
            },
        },
        details: {
            availability: {
                container: {
                    styles: [
                        {
                            values: {
                                display: 'flex',
                                marginTop: '.6rem',
                                fontSize: '.8rem',
                                alignItems: 'center',
                            },
                            order: [
                                'display',
                                'marginTop',
                                'fontSize',
                                'alignItems',
                            ],
                        },
                    ],
                },
                indicator: {
                    styles: [
                        {
                            values: {
                                height: '8px',
                                width: '8px',
                                backgroundColor:
                                    '$colors.availability.inStock',
                                marginRight: '.4rem',
                                borderRadius: '8px',
                            },
                            order: [
                                'height',
                                'width',
                                'backgroundColor',
                                'marginRight',
                                'borderRadius',
                            ],
                        },
                    ],
                },
            },
            description: {
                alt: {
                    styles: [
                        {
                            values: {
                                position: 'fixed',
                                backgroundColor: 'white',
                                maxWidth: '500px',
                                height: 'auto',
                                zIndex: '1000000000',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                padding: '0.5rem',
                                borderRadius: '4px',
                                boxShadow:
                                    '0 1px 10px rgba(0, 0, 0, 0.2)',
                                fontSize: '0.8rem',
                            },
                            order: [
                                'position',
                                'backgroundColor',
                                'maxWidth',
                                'height',
                                'zIndex',
                                'overflow',
                                'textOverflow',
                                'padding',
                                'borderRadius',
                                'boxShadow',
                                'fontSize',
                            ],
                        },
                    ],
                },
                styles: [
                    {
                        values: {
                            fontSize: '0.8rem',
                            lineHeight: '1.5',
                            marginTop: '1rem',
                            display: 'none',
                            flexDirection: 'column',
                            cursor: 'pointer',
                            marginBottom: '.5rem',
                        },
                        order: [
                            'fontSize',
                            'lineHeight',
                            'marginTop',
                            'display',
                            'flexDirection',
                            'cursor',
                            'marginBottom',
                        ],
                    },
                    null,
                    {
                        values: {
                            display: 'flex',
                        },
                        order: ['display'],
                    },
                ],
                row: {
                    styles: [
                        {
                            values: {
                                display: 'block',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap',
                                cursor: 'pointer',
                            },
                            order: [
                                'display',
                                'overflow',
                                'textOverflow',
                                'whiteSpace',
                                'cursor',
                            ],
                        },
                    ],
                },
            },
            title: {
                styles: [
                    {
                        values: {
                            fontWeight: '700',
                            fontSize: '1rem',
                        },
                        order: ['fontWeight', 'fontSize'],
                    },
                    null,
                    {
                        values: { fontSize: '1.3rem' },
                        order: ['fontSize'],
                    },
                ],
            },
            attributes: {
                styles: [
                    {
                        values: {
                            marginTop: '.75rem',
                        },
                        order: ['marginTop'],
                    },
                ],
            },
        },
        prices: {
            container: {
                styles: [
                    {
                        values: {
                            display: 'flex',
                            alignItems: 'flex-end',
                            flexWrap: 'wrap',
                        },
                        order: [
                            'display',
                            'alignItems',
                            'flexWrap',
                        ],
                    },
                ],
            },
            styles: [
                {
                    values: {
                        height: 'auto',
                        display: 'inline-flex',
                        width: 'auto',
                        marginRight: '.5rem',
                    },
                    order: [
                        'height',
                        'display',
                        'width',
                        'marginRight',
                    ],
                },
            ],
        },
        pricesWithEyecatcher: {
            styles: [
                {
                    values: {
                        alignItems: 'center',
                    },
                    order: ['alignItems'],
                },
            ],
        },
        price: {
            styles: [
                {
                    values: {
                        fontSize: '1.3rem',
                        fontWeight: '600',
                        lineHeight: '1',
                        textWrap: 'nowrap',
                    },
                    order: [
                        'fontSize',
                        'fontWeight',
                        'lineHeight',
                        'textWrap',
                    ],
                },
                null,
                {
                    values: { fontSize: '1.3rem' },
                    order: ['fontSize'],
                },
            ],
        },
        oldPrice: {
            styles: [
                {
                    values: {
                        marginRight: '.5rem',
                        textDecoration: 'line-through',
                        textDecorationColor: '$colors.sale',
                    },
                    order: [
                        'marginRight',
                        'textDecoration',
                        'textDecorationColor',
                    ],
                },
            ],
        },
        salePrice: {
            styles: [
                {
                    values: { color: '$colors.sale' },
                    order: ['color'],
                },
            ],
        },
        lowestPrice: {
            styles: [
                {
                    values: {
                        opacity: '.5',
                        fontSize: '.6rem',
                    },
                    order: ['opacity', 'fontSize'],
                },
                null,
                {
                    values: {
                        opacity: '.5',
                        fontSize: '.7rem',
                    },
                    order: ['opacity', 'fontSize'],
                },
            ],
        },
        priceInformation: {
            container: {
                styles: [
                    {
                        values: {
                            display: 'flex',
                            flexDirection: 'column',
                            order: '2',
                            marginTop: '0.5rem',
                        },
                        order: [
                            'display',
                            'flexDirection',
                            'order',
                            'marginTop',
                        ],
                    },
                    {
                        values: {
                            width: '100%',
                        },
                        order: ['width'],
                    },
                ],
            },
            styles: [
                {
                    values: {
                        opacity: '.5',
                        fontSize: '.6rem',
                    },
                    order: ['opacity', 'fontSize'],
                },
                null,
                {
                    values: {
                        opacity: '.5',
                        fontSize: '.7rem',
                    },
                    order: ['opacity', 'fontSize'],
                },
            ],
        },
        priceInfo: {
            container: {
                styles: [
                    {
                        values: {
                            fontSize: '.7rem',
                            lineHeight: '1',
                            marginTop: '.5rem',
                        },
                        order: [
                            'fontSize',
                            'lineHeight',
                            'marginTop',
                        ],
                    },
                    null,
                    {
                        values: {
                            fontSize: '.8rem',
                            width: 'auto',
                        },
                        order: ['fontSize', 'width'],
                    },
                ],
            },
        },
        buttonGroup: {
            container: {
                styles: [
                    {
                        values: {
                            display: 'flex',
                            flexDirection: 'column',
                            marginTop: '.75rem',
                            width: '100%',
                            justifyContent: 'flex-end',
                            position: 'relative',
                            height: '3rem',
                        },
                        order: [
                            'display',
                            'flexDirection',
                            'marginTop',
                            'width',
                            'justifyContent',
                            'position',
                            'height',
                        ],
                    },
                    null,
                    {
                        values: {
                            height: '6.5rem',
                        },
                        order: ['height'],
                    },
                ],
            },
        },
        buttons: {
            default: {
                '&:last-child': {
                    styles: [
                        {
                            values: {
                                marginBottom: '0rem',
                            },
                            order: ['marginBottom'],
                        },
                    ],
                },
                styles: [
                    {
                        values: {
                            border: '1px solid $colors.border',
                            color: '$colors.primary',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            height: '3rem',
                            marginBottom: '.5rem',
                            fontSize: '1rem',
                            borderRadius: '2px',
                        },
                        order: [
                            'border',
                            'color',
                            'display',
                            'justifyContent',
                            'alignItems',
                            'height',
                            'marginBottom',
                            'fontSize',
                            'borderRadius',
                        ],
                    },
                ],
            },
            primary: {
                styles: [
                    {
                        values: {
                            backgroundColor: '#e9484d',
                            color: 'white',
                            marginBottom: '0',
                            fontWeight: 'bold',
                        },
                        order: [
                            'backgroundColor',
                            'color',
                            'marginBottom',
                            'fontWeight',
                        ],
                    },
                ],
                icon: {
                    default: {
                        styles: [
                            {
                                values: {
                                    width: '1.5rem',
                                    height: '1.5rem',
                                    backgroundSize:
                                        'contain',
                                    backgroundRepeat:
                                        'no-repeat',
                                    marginRight: '.5rem',
                                },
                                order: [
                                    'width',
                                    'height',
                                    'backgroundSize',
                                    'backgroundRepeat',
                                    'marginRight',
                                ],
                            },
                        ],
                    },
                    info: {
                        styles: [
                            {
                                values: {
                                    backgroundImage:
                                        'url($assets.iconsInfo)',
                                },
                                order: ['backgroundImage'],
                            },
                        ],
                    },
                    cart: {
                        styles: [
                            {
                                values: {
                                    backgroundImage:
                                        'url($assets.iconsCart)',
                                },
                                order: ['backgroundImage'],
                            },
                        ],
                    },
                },
            },
            buy: {
                styles: [
                    {
                        values: {
                            backgroundColor: 'white',
                            marginLeft: '.75rem',
                            backgroundImage:
                                'url($assets.icons.cart.black)',
                            minWidth: '3rem',
                            backgroundSize: '35%',
                            backgroundPosition: '45% 50%',
                            backgroundRepeat: 'no-repeat',
                            borderRadius: '2px',
                            border: '1px solid black',
                        },
                        order: [
                            'backgroundColor',
                            'marginLeft',
                            'backgroundImage',
                            'minWidth',
                            'backgroundSize',
                            'backgroundPosition',
                            'backgroundRepeat',
                            'borderRadius',
                            'border',
                        ],
                    },
                ],
            },
            secondary: {
                styles: [
                    {
                        values: {
                            fontSize: '.8rem',
                            position: 'absolute',
                            top: '100px',
                            border: 'none',
                            flexDirection: 'column',
                            color: 'white',
                        },
                        order: [
                            'fontSize',
                            'position',
                            'top',
                            'border',
                            'flexDirection',
                            'color',
                        ],
                    },
                    null,
                    {
                        values: {
                            textTransform: 'uppercase',
                            fontSize: '.8rem',
                            flexDirection: 'row',
                            position: 'static',
                            border: '1px solid $colors.border',
                            color: '$colors.primary',
                            marginTop: '.5rem',
                        },
                        order: [
                            'textTransform',
                            'fontSize',
                            'flexDirection',
                            'position',
                            'border',
                            'color',
                            'marginTop',
                        ],
                    },
                ],
            },
        },
    },
    search: {
        container: {
            styles: [
                {
                    values: {
                        borderColor: '$colors.border',
                        borderStyle: 'solid',
                        display: 'flex',
                    },
                    order: [
                        'borderColor',
                        'borderStyle',
                        'display',
                    ],
                },
                null,
                null,
                {
                    values: {
                        alignItems: 'center',
                        position: 'relative',
                    },
                    order: ['alignItems', 'position'],
                },
            ],
        },
        form: {
            styles: [
                {
                    values: {
                        display: 'block',
                        width: '100%',
                        position: 'relative',
                    },
                    order: ['display', 'width', 'position'],
                },
            ],
        },
        input: {
            styles: [
                {
                    values: {
                        border: 'none',
                        padding: '.5rem',
                        height: '30px',
                        fontSize: '13px',
                    },
                    order: [
                        'border',
                        'padding',
                        'height',
                        'fontSize',
                    ],
                },
                null,
                null,
                {
                    values: {
                        backgroundColor: '$colors.border',
                        borderRadius: '4px',
                    },
                    order: [
                        'backgroundColor',
                        'borderRadius',
                    ],
                },
            ],
        },
        icon: {
            styles: [
                {
                    values: {
                        position: 'absolute',
                        top: '0',
                        right: '0',
                        backgroundImage:
                            'url($assets.search)',
                        backgroundSize: '75%',
                        backgroundRepeat: 'no-repeat',
                        backgroundPosition: 'center',
                        height: '100%',
                        width: '30px',
                        cursor: 'pointer',
                    },
                    order: [
                        'position',
                        'top',
                        'right',
                        'backgroundImage',
                        'backgroundSize',
                        'backgroundRepeat',
                        'backgroundPosition',
                        'height',
                        'width',
                        'cursor',
                    ],
                },
            ],
        },
    },
    navigation: {
        container: {
            styles: [
                {
                    values: {
                        justifyContent: 'center',
                        marginTop: '1.5rem',
                        marginBottom: '.5rem',
                    },
                    order: [
                        'justifyContent',
                        'marginTop',
                        'marginBottom',
                    ],
                },
            ],
        },
        item: {
            styles: [
                {
                    values: {
                        height: '2rem',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    },
                    order: [
                        'height',
                        'display',
                        'justifyContent',
                        'alignItems',
                    ],
                },
            ],
        },
        link: {
            '&:hover': {
                styles: [
                    {
                        values: {
                            color: '$colors.sale',
                        },
                        order: ['color'],
                    },
                ],
            },
            styles: [
                {
                    values: {
                        paddingLeft: '1rem',
                        paddingRight: '1rem',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        fontSize: '13px',
                        textTransform: 'uppercase',
                        color: '#999999',
                    },
                    order: [
                        'paddingLeft',
                        'paddingRight',
                        'display',
                        'alignItems',
                        'justifyContent',
                        'fontSize',
                        'textTransform',
                        'color',
                    ],
                },
            ],
        },
    },
    footer: {
        priceInfo: {
            row: {
                styles: [
                    {
                        values: {
                            textAlign: 'center',
                            display: 'block',
                        },
                        order: ['textAlign', 'display'],
                    },
                ],
            },
            link: {
                styles: [
                    {
                        values: {
                            color: 'inherit',
                        },
                        order: ['color'],
                    },
                ],
            },
        },
        link: {
            styles: [
                {
                    values: {
                        margin: '1rem',
                        color: 'inherit',
                    },
                    order: ['margin', 'color'],
                },
            ],
        },
        links: {
            container: {
                styles: [
                    {
                        values: {
                            display: 'flex',
                            justifyContent: 'center',
                        },
                        order: [
                            'display',
                            'justifyContent',
                        ],
                    },
                ],
            },
        },
    },
    layout: {
        horizontalList: {
            ul: {
                styles: [
                    {
                        values: {
                            listStyle: 'none',
                            display: 'flex',
                            flexDirection: 'row',
                            margin: '0',
                            padding: '0',
                        },
                        order: [
                            'listStyle',
                            'display',
                            'flexDirection',
                            'margin',
                            'padding',
                        ],
                    },
                ],
            },
        },
    },
    byClasses: {
        logo: {
            styles: [
                {
                    values: {
                        justifyContent: 'center',
                        display: 'flex',
                    },
                    order: ['justifyContent', 'display'],
                },
            ],
        },
    },
}
