import React from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { Box, Link } from '@conversionbuddy/Layout'
import { ProductImagesContainer as ProductImages } from './Images'
import { ProductDetails } from './Details'
import { useHover } from '../../../../hooks/useHover'
import { useTouch } from '../../../../hooks/useTouch'
import { useAppeared } from '../../../../hooks/useAppeared'
import { usePromotion } from '../../../../hooks/usePromotion'

const types = {
    product: PropTypes.object.isRequired,
    onAppear: PropTypes.func.isRequired,
    onClick: PropTypes.func.isRequired,
    index: PropTypes.number.isRequired,
}

export const Product = ({
    index,
    onAppear,
    onClick,
    product,
}) => {
    const [ref, appeared] = useAppeared(onAppear)
    const [hovered, handlers] = useHover()
    const [touched, touchHandlers] = useTouch()
    const imageDimensions = useSelector(
        (state) => state.general.dimensions,
    )

    const promotion = usePromotion(product)

    const handleClick = (e) => {
        e.preventDefault()
        onClick(product, null, promotion)
    }

    const handleClickAttribute = (sku) => {
        onClick(product, sku, promotion)
    }

    const isAttributesVisible = Boolean(
        hovered && product.uniqueCharacteristics,
    )

    return (
        <Link
            href={product.skus[0].url}
            title={product.id}
            onClick={handleClick}
            {...handlers}
            {...touchHandlers}
        >
            <Box
                ref={ref}
                styles={[
                    `
                        width: auto;
                        height: auto;
                    `,
                ]}
            >
                <ProductImages
                    hovered={hovered || touched}
                    imageDimensions={imageDimensions}
                    images={product.images}
                    isFirstProduct={index === 0}
                    product={product}
                    show={appeared || index < 10}
                />
                <ProductDetails
                    product={product}
                    showAttributes={isAttributesVisible}
                    onClick={handleClickAttribute}
                />
            </Box>
        </Link>
    )
}

Product.propTypes = types
