import React, { useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { getStyles } from '@conversionbuddy/Layout'
import { useDimensions } from '../../../../../../hooks/useDimensions'
import { useTranslation } from '../../../../../../hooks/useTranslation'
import { sort } from '../../../../../../utils/sort'

const StyledProductAttributes = styled.div`
    bottom: ${(props) => props.offset}px;
    display: ${(props) =>
        props.visible ? 'flex' : 'none'};
    ${getStyles([
        'productsGrid.product.details.attributes.container',
    ])}
`

const StyledProductAttribute = styled.div`
    margin-right: 1rem;
    font-size: 0.9rem;
`

/**
 * Checks if a string contains whitespace.
 *
 * @param {string} str - The string to check.
 * @returns {boolean} `true` if the string contains a whitespace character, otherwise `false`.
 */
export const hasWhiteSpace = (str) =>
    str.indexOf(' ') !== -1

/**
 * Checks if the only unique characteristic of a product is its size.
 *
 * @param {Object} product - The product object to be checked.
 * @param {Array<string>} product.uniqueCharacteristics - A list of the product's unique characteristics.
 * @returns {boolean} - Returns `true` if 'size' is the only unique characteristic, otherwise `false`.
 */
export const isSizeOnlyUniqueCharacteristic = (product) =>
    product.uniqueCharacteristics.includes('size') &&
    product.uniqueCharacteristics.length === 1

/**
 * A component that displays the available sizes of a product.
 *
 * @param {Object} props - The component props.
 * @param {Array<string>} props.sizes - A list of available sizes.
 * @param {number} props.width - The available width for rendering.
 * @returns {JSX.Element|string} A list of sizes or a message indicating that the product is available in many sizes.
 */
const ProductSizes = ({ sizes, width }) => {
    const messageManySizes = useTranslation(
        'productAvailability.manySizes',
    )

    const isRenderAsList = useCallback(() => {
        const { l, s, w } = sizes.reduce(
            (a, s) => {
                a.w = a.w + 16 + s.length * 9
                a.l = Math.max(a.l, s.length)
                a.s = a.s || hasWhiteSpace(s)
                return a
            },
            { w: 0, l: 0, s: false },
        )

        // estimated width < available width AND
        // all sizes are less than 5 characters AND
        // no size has whitespace
        return (
            sizes.length === 1 || (w < width && l < 5 && !s)
        )
    }, [sizes, width])

    if (!isRenderAsList()) {
        return messageManySizes
    }

    return sizes.map((size) => (
        <StyledProductAttribute key={size}>
            {size}
        </StyledProductAttribute>
    ))
}

/**
 * A component that handles displaying the attributes of a product, specifically for size attributes.
 *
 * @param {Object} props - The component props
 * @param {React.RefObject} props.boxRef - A reference to the box element for determining dimensions.
 * @param {Object} props.product - The product whose attributes should be displayed.
 * @param {boolean} props.visible - Determines if the attributes should be visible.
 * @returns {JSX.Element|null} A list of attributes if the product has multiple sizes, otherwise `null`.
 */
export const ProductAttributes = ({
    boxRef,
    product,
    visible,
}) => {
    const [_, dimensions] = useDimensions(true, boxRef)
    const sizes = useMemo(
        () =>
            isSizeOnlyUniqueCharacteristic(product)
                ? sort(product.skus.map((sku) => sku.size))
                : null,
        [product],
    )

    return (
        sizes && (
            <StyledProductAttributes
                data-testid={`pg/attributes/${product.id}`}
                offset={dimensions.height}
                visible={visible}
            >
                <ProductSizes
                    sizes={sizes}
                    width={dimensions.width}
                />
            </StyledProductAttributes>
        )
    )
}

ProductAttributes.propTypes = {
    boxRef: PropTypes.any.isRequired,
    visible: PropTypes.bool,
    product: PropTypes.object.isRequired,
}

ProductAttributes.defaultProps = {
    visible: false,
}
